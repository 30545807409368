import { React, useEffect, useState, useCallback } from "react";
import { Modal} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import DistributionType from "./DistributionsType";
import DeleteAccountModal from "./DeleteAccountModal";
import DeleteDriverModal from "./DeleteDriverModal";
import DeleteDimensionModal from "./DeleteDimensionModal";
import DeleteNotificationModal from "./DeleteNotificationModal";
import { closeModal } from "../../../store/slices/modal/modalSlice";
import ResetPasswordUser from "./ResetPasswordUser";
import ChangeStatusUser from "./ChangeStatusUser";
import SuperAdminStatus from "./SuperAdminStatus";
import RoleAssignment from "./RoleAssignment";
import SnowflakeDeleteContract from "./SnowflakeDeleteContract";
import SnowflakeVerifyContract from "./SnowflakeVerifyContract";
import SnowflakeIncompleteFields from "./SnowflakeIncompleteFields";
import ScheduleRefresh from "./SchduleRefresh";

const ParentModal = () => {
    const dispatch = useDispatch();

    const { openModalState, modalSelected } = useSelector( store => store.modal )
   
  return (
    <Modal
        show={ openModalState }
        onHide={()=>{dispatch(closeModal())}}
        {...(modalSelected === 'SheduleRefresh' && {
          size: "lg",
          "aria-labelledby": "contained-modal-title-vcenter",
          centered: true,
        })}
    >
      {
           (modalSelected === 'DistributionType' && <DistributionType/>)
           || (modalSelected) === 'DeleteButtonAccount' && (<DeleteAccountModal/>)
           || (modalSelected) === 'DeleteButtonDriver' && (<DeleteDriverModal/>)
           || (modalSelected) === 'DeleteDimension' && (<DeleteDimensionModal/>)
           || (modalSelected) === 'DeleteNotification' && (<DeleteNotificationModal/>)
           || (modalSelected) === 'ResetPasswordUser' && (<ResetPasswordUser/>)
           || (modalSelected) === 'ChangeStatusUser' && (<ChangeStatusUser/>)       
           || (modalSelected) === 'SnowflakeDeleteContract' && (<SnowflakeDeleteContract/>)
           || (modalSelected) === 'SnowflakeVerifyContract' && (<SnowflakeVerifyContract/>)
           || (modalSelected) === 'SnowflakeIncompleteFields' && (<SnowflakeIncompleteFields/>)
           || (modalSelected) === 'SuperAdminStatus' && (<SuperAdminStatus/>)
           || (modalSelected) === 'RoleAssignment' && (<RoleAssignment/>)
           || (modalSelected) === 'SheduleRefresh' && (<ScheduleRefresh/>)
           

      }
    </Modal>
  )
}

export default ParentModal