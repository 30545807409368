import { useAuth0 } from '@auth0/auth0-react'
import { React,useState,useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { forgotPassword } from '../../../store/slices/users/usersThunks'
// import NewUserRegisterScreen from './NewUserRegisterScreen'
// import ResetPasswordScreen from './ResetPasswordScreen'
import '../../../styles/resetPassword.css'
const ResetPasswordScreen = () => {
    const dispatch = useDispatch();
    const { loginWithRedirect } = useAuth0();
    const [error, setErrors] = useState(false);
    const [input, setInput] = useState({
      email: "",
    });

    const handleChange = (e) => {
      setInput({
        ...input,
        [e.target.id]: e.target.value,
      });
      console.log(input.email);
    };
  //create a function to validate if input.email content @ 
    
    const handleSubmit = (e) => {
      e.preventDefault();
      if (input.email.length > 0) {
        dispatch(forgotPassword(input.email));
        setTimeout(() => {
          loginWithRedirect();
        }, 1000);
      } else {
        setErrors(true);
        setTimeout(() => {
          setErrors(false);
        }, 2000);
      }
    };
    
  return (
  <>
  <div>
  <html class="body-html-containerpassword">
<head>
  <meta charset="utf-8"/>
  <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1"/>
  <title>Sign In with Auth0</title>
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"/>
  
</head>
<body class="body-html-containerpassword">
  <div class="login-container">
    <div class="col-xs-12 col-sm-4 col-sm-offset-4 login-box">
      <div class="login-header">
      <img src="https://i.postimg.cc/yNZ7zy5j/favicon.jpg" style={{width:"300px", height: "auto"}} />
        <h3>Reset your password</h3>
        <h5>Please enter your email , we will send a message to change your password</h5>
      { error ? <span class="error-msg"> Wrong email</span> : <span></span>}
      </div>
      <div id="error-message" class="alert alert-danger"></div>
      <form onsubmit="return false;" method="post">
        <div class="form-group">
          <label for="email">Email</label>
          <input
            maxLength={50}
            type="email"
            class={`form-control`}
            id="email"
            onChange={(e) =>handleChange(e)} 
            placeholder={error ? "Complete the field... ":"Enter your email"}/>
        </div>
      
        <button
          type="button"
          onClick={(e)=>{handleSubmit(e)}}
          class={error ? "btn btn-danger btn-block": "btn btn-primary btn-block"}>
           Send email
        </button>
      </form>
    </div>
  </div> 
</body>
</html>
  </div>
  
  </>)   
}
   
  

export default ResetPasswordScreen