import React , {useEffect,useState} from "react";
import { Row, Col, Image } from "react-bootstrap";
import Card from "../../components/Card";
import { getListNotifications } from "../../store/slices/notifications/notificationThunks";
import { resetEditState } from "../../store/slices/notifications/notificationSlice";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../Loader";
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';



const Notifications = () => {

  const [formattedDates, setFormattedDates] = useState([]);

  const dispatch = useDispatch();
  const { idTenant } = useSelector((state) => state.costing);
  const { notifications,notificationState } = useSelector((state) => state.notifications);


  useEffect(() => {

    dispatch(getListNotifications({tenantid: idTenant}))
    dispatch(resetEditState())
  }, []);

  useEffect(() => {
    if (Array.isArray(notifications)) {
      // Convertir las fechas a la zona horaria del usuario y almacenarlas en el estado
      const formattedDatesArray = notifications.map((noti) => {
        const userTimeZoneDate = new Date(noti.creationDate);
        return userTimeZoneDate.toLocaleString(undefined, {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          timeZoneName: "short",
        });
      });
      setFormattedDates(formattedDatesArray);
    }
  }, [notifications]);

  if(notificationState === 'processing'){
    return <Loader center={true}/>
}
const notificationsBackup = [] 
  return (
    <>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Notifications History</h4>
              </div>
            </Card.Header>
            <Card.Body className="p-0">
              <div className="table-responsive mt-4">
              { notifications?.length > 0 ?   ( <table
                  id="basic-table"
                  className="table table-striped mb-0"
                  role="grid"
                >
             <thead>
                    <tr>
                      <th>Account</th>
                      <th>Services</th>
                      <th>Notification Name</th>
                      <th>Amount</th>
                      <th>Percent</th>
                      <th>Status</th>
                      <th>Date</th>
                    </tr>
                  </thead>
              
                <tbody>
                  {  notifications.map((noti,index) => (  
                    <tr key={index}>
                      <td>
                        <div className="d-flex align-items-center">
                          <Image
                            className="rounded img-fluid avatar-40 me-3 bg-soft-primary"
                            src={`/${noti.consolidated ? 'cco.png'  : noti.cloudProvider + ".png"}`}
                            alt="profile"
                          />
                          <h6>{noti.accountName}</h6>
                        </div>
                      </td>
                      <td>
                       { noti.serviceNotification !== null ?  <h6>{noti.serviceName}</h6> : <h6>All services</h6>}
                      </td>

                      <td>{noti.notificationName}</td>
                      <td>$ {parseFloat(noti.amount).toFixed(2)}</td>
                      <td>{noti[noti.notificationType]}% of your threshold </td>
                      <td>
                        <div className={noti.notificationType === 'alert' ? 'text-danger' : noti.notificationType === 'warning' ? 
                        'text-warning' : noti.notificationType === 'information' ? 'text-success' : ''}>
                          {noti.notificationType.charAt(0).toUpperCase() + noti.notificationType.slice(1)}
                          
                          </div>
                      </td>
                      <td>{formattedDates[index]}</td>                      
                    </tr>
                    ))
                
                    } 
                
                  </tbody>
                </table>): 
                   (  <div style={{  display: 'flex',
                   alignItems: 'center',   
                      justifyContent: 'center',fontSize:'25px',
                      height: '40vh',  }}> 
                      <svg style={{marginRight:'10px'}} xmlns="http://www.w3.org/2000/svg" width="40" height="36" fill="currentColor" class="bi bi-bell-slash" viewBox="0 0 16 16">
  <path d="M5.164 14H15c-.299-.199-.557-.553-.78-1-.9-1.8-1.22-5.12-1.22-6 0-.264-.02-.523-.06-.776l-.938.938c.02.708.157 2.154.457 3.58.161.767.377 1.566.663 2.258H6.164zm5.581-9.91a3.986 3.986 0 0 0-1.948-1.01L8 2.917l-.797.161A4.002 4.002 0 0 0 4 7c0 .628-.134 2.197-.459 3.742-.05.238-.105.479-.166.718l-1.653 1.653c.02-.037.04-.074.059-.113C2.679 11.2 3 7.88 3 7c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0c.942.19 1.788.645 2.457 1.284l-.707.707zM10 15a2 2 0 1 1-4 0zm-9.375.625a.53.53 0 0 0 .75.75l14.75-14.75a.53.53 0 0 0-.75-.75z"/>
</svg>
There are no notifications sent ...    
                      </div> )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Notifications;